@import '../site/colors.scss';
@import '../_import.scss';

* {
	&, &:before, &:after {
		box-sizing: border-box;
		overflow: hidden;
	}
}

html, body, main {
	margin: 0;
	padding: 0;
}

body {
	font-family: Interstate, Roboto, sans-serif;
	font-weight: 500;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center top;
	display: grid;
	justify-content: center;
	align-content: center;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
}

main {
	width: 100vw;
	height: auto;
	max-height: 95vh;
	overflow-y: auto;
	overflow-x: hidden;
	display: grid;
	justify-content: center;
	align-content: center;
	padding: 8rem 0;
	background-color: rgba(255, 255, 255, 0.4);
	backdrop-filter: blur(8px) grayscale(90%);
	display: grid;
}

.main-container {
	display: grid;
	justify-content: center;
	align-content: center;
	width: 1100px;
	max-width: 95vw;
	padding: 0 2rem;
	overflow: hidden;
}

h1 {
	@include font-bold;
	font-size: 3.75rem;
	margin: 0 0 1rem 0;
}

.code {
	font-weight: 700;
	color: var(--extra-dark-text);
}

.title {
	color: var(--blue-text);
}

p {
	color: var(--extra-dark-text);
	font-size: 1.25rem;
	line-height: 1.7;
	margin: 0 0 1.25rem 0;
}

a {
	color: var(--link-text);

	&:hover {
		color: var(--link-hover);
	}

	&:visited {
		color: var(--link-visited);
	}
}
